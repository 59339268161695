import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FiMapPin } from "react-icons/fi";

// Keyframe for blinking effect
const blink = keyframes`
  0%, 50%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  text-align: center;
`;

const BlinkingText = styled.p`
  color: #ff0000;
  font-weight: bold;
  animation: ${blink} 1.5s infinite;
`;

const InstructionText = styled.p`
  margin: 10px 0;
  color: #555;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const LocationInfo = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #333;
`;

const GetUserLocation = ({ location, setLocation }) => {
  const getLocation = () => {
    if (!navigator.geolocation) {
      setLocation({
        ...location,
        error: "Geolocation is not supported by your browser.",
      });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude,
          error: null,
        });
      },
      (error) => {
        setLocation({
          ...location,
          error: error.message,
        });
      }
    );
  };

  return (
    <Container>
      <BlinkingText>
        <FiMapPin size={24} /> Enable location access to sign the document.
      </BlinkingText>

      <InstructionText>
        If you haven't granted access, please enable location services in your
        browser settings and reload the page.
      </InstructionText>

      {!location.latitude || !location.longitude ? (
        <StyledButton onClick={getLocation}>Get Location</StyledButton>
      ) : (
        <LocationInfo>
          📍 Latitude: {location.latitude}, Longitude: {location.longitude}
        </LocationInfo>
      )}

      {location.error && (
        <InstructionText>Error: {location.error}</InstructionText>
      )}
    </Container>
  );
};

export default GetUserLocation;
