import "./App.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
import Navbar, { logout } from "./components/Navbar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { Hdr, ViewHdr, HdrDetails } from "./pages/HDR";
import { Tenant } from "./pages/Tenancy";
import NotFound from "./pages/404";
import Layout from "./components/Layout";
import { HashRouter as BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-overlay-loader/styles.css";
import ESign from "./pages/ESign";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import PCP from "./pages/PCP";
import DataBreach from "./pages/DataBreach";
import { useIdleTimer } from "react-idle-timer";
import ViewTenancyList from "./pages/Tenancy/ViewTenancyList";
import TenancyDetails from "./pages/Tenancy/TenancyDetails";
import ViewPCPList from "./pages/PCP/PCPList";
import PCPDetails from "./pages/PCP/PCPDetails";
import ViewDataBreachList from "./pages/DataBreach/DataBreachList";
import DataBreachDetails from "./pages/DataBreach/DataBreachDetails";
import { Ol } from "./pages/ol";
import ViewOlList from "./pages/ol/ViewOlList";
import OlDetails from "./pages/ol/OlDetails";
import Pl from "./pages/pl/pl";
import ViewPlList from "./pages/pl/ViewPlList";
import PlDetails from "./pages/pl/PlDetails";
import El from "./pages/el/el";
import ViewElList from "./pages/el/ViewElList";
import ElDetails from "./pages/el/ElDetails";
import ViewInvoiceList from "./pages/Invoice/InvoiceList";
import InvoiceDetails from "./pages/Invoice/invoiceDetails";
import InvoiceFilter from "./pages/Invoice/InvoiceFilter";
import Role from "./pages/register/Role";
import RoleRights from "./pages/register/RoleRights";
import Module from "./pages/register/Module";
import Pages from "./pages/register/Pages";
import WorkFlow from "./pages/workFlow/WorkFlow";
import CompanyDetails from "./pages/register/CompanyDetails";
import { refreshToken } from "./services/Auth";
import { useEffect, useState } from "react";
import Immigration from "./pages/Immigration/immigration";
import ViewImmigrationList from "./pages/Immigration/ViewImmigraionList";
import ImmigrationDetails from "./pages/Immigration/immigrationDetails";
import MedicalNegligence from "./pages/MedicalNegligence/MedicalNegligence";
import ViewMedicalNegligenceList from "./pages/MedicalNegligence/ViewMedicalNegligenceList";
import MedicalNegligenceDetails from "./pages/MedicalNegligence/MedicalNegligenceDetails";
import UserChangePassword from "./pages/CompanySettings/UserChangePassword";
import Hire from "./pages/Hire/Hire";
import HireDetails from "./pages/Hire/HireDetails";
import ViewHireList from "./pages/Hire/ViewHireList";
import ViewRTAList from "./pages/rta/ViewRta";
import RTA from "./pages/rta/RTA";
import RtaDetails from "./pages/rta/RtaDetails";
import ManualInvoice from "./pages/Invoice/ManualInvoice";
import ManualInvoiceDetails from "./pages/Invoice/ManualInvoiceDetails";
import AddBroadcast from "./pages/Dashboard/Broadcast/AddBroadcast";
import ViewAllBroadcast from "./pages/Dashboard/Broadcast/ViewAllBroadcast";
import ChangeUserPassword from "./pages/ChangeUserPassword/ChangeUserPassword";
import CaseList from "./pages/CaseList";
import HdrCaseReport from "./pages/HDR/HdrCaseReport";
import HireCaseReport from "./pages/Hire/HireCaseReport";
import BroadcastDescr from "./pages/Dashboard/Broadcast/BroadcastDescr";
import AddCompanyCFA from "./pages/CompanyCFA/AddCompanyCFA";
import EmailLinkRedirect from "./pages/EmailLinkRedirect/EmailLinkRedirect";
import AuthVerify from "./pages/AuthVerify/AuthVerify";
import EmailStatus from "./pages/EmailStatus/EmailStatus";
import ViewAllCompanies from "./pages/UserManagement/ViewAllCompanies";
import ViewCompanyProfile from "./pages/UserManagement/CompanyProfile.js/ViewCompanyProfile";
import ViewCompanyJobs from "./pages/UserManagement/CompanyJobs.js/ViewCompanyJobs";
import ViewCompanyUsers from "./pages/UserManagement/CompanyUsers/ViewCompanyUsers";
import CompanyDoc from "./pages/UserManagement/CompanyDoc";

function App() {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const { token, pwdUpdateFlag } = useSelector(
    (state) => state.authenticationSlice
  );
  const { loading } = useSelector((state) => state.loadingSlice);
  const routes = <Route path="/eSign" element={<ESign />} />;
  const [validToken, setValidToken] = useState(false);
  // const nav = useSelector((state) => state.authenticationSlice.nav);
  // const navRoutes = JSON.parse(nav);

  // const allowedRoutes = navRoutes?.map((item, index) => {
  //   return item?.items?.map((item, index) => {
  //     return item?.to;
  //   });
  // });

  // const flatRoutes = allowedRoutes?.flat();
  // console.log(flatRoutes, "flatRoutes");

  // const renderPrivateRoutes = () => {
  //   return (
  //     flatRoutes &&
  //     flatRoutes.map((to, index) => {
  //       console.log(to, "to");
  //       switch (to) {
  //         case "/eSign":
  //           return <Route key={index} path={to} element={<ESign />} />;
  //         case "/hdr":
  //           return (
  //             user?.tblUsercategory?.categorycode !== "2" && (
  //               <Route key={index} path={to} element={<Hdr />} />
  //             )
  //           );
  //         case "/tenancy":
  //           return (
  //             user?.tblUsercategory?.categorycode !== "2" && (
  //               <Route key={index} path={to} element={<Tenant />} />
  //             )
  //           );
  //         case "/viewHdr":
  //           return <Route key={index} path={to} element={<ViewHdr />} />;
  //         case "/hdrDetails":
  //           return <Route key={index} path={to} element={<HdrDetails />} />;
  //         case "/pcp":
  //           return <Route key={index} path={to} element={<PCP />} />;
  //         case "/pcpDetails":
  //           return <Route key={index} path={to} element={<PCPDetails />} />;
  //         case "/pcpList":
  //           return <Route key={index} path={to} element={<ViewPCPList />} />;
  //         case "/dataBreach":
  //           return <Route key={index} path={to} element={<DataBreach />} />;
  //         case "/dataBreachDetails":
  //           return (
  //             <Route key={index} path={to} element={<DataBreachDetails />} />
  //           );
  //         case "/dataBreachList":
  //           return (
  //             <Route key={index} path={to} element={<ViewDataBreachList />} />
  //           );
  //         case "/tenancylist":
  //           return (
  //             <Route key={index} path={to} element={<ViewTenancyList />} />
  //           );
  //         case "/tenancyDetails":
  //           return <Route key={index} path={to} element={<TenancyDetails />} />;
  //         case "/ol":
  //           return <Route key={index} path={to} element={<Ol />} />;
  //         case "/ollist":
  //           return <Route key={index} path={to} element={<ViewOlList />} />;
  //         case "/oldetails/:code":
  //           return <Route key={index} path={to} element={<OlDetails />} />;
  //         case "/billingList":
  //           return (
  //             user?.tblUsercategory?.categorycode === "4" && (
  //               <Route key={index} path={to} element={<ViewInvoiceList />} />
  //             )
  //           );
  //         case "/invoiceDetails":
  //           return (
  //             user?.tblUsercategory?.categorycode === "4" && (
  //               <Route key={index} path={to} element={<InvoiceDetails />} />
  //             )
  //           );
  //         case "/invoiceFilter":
  //           return (
  //             user?.tblUsercategory?.categorycode === "4" && (
  //               <Route key={index} path={to} element={<InvoiceFilter />} />
  //             )
  //           );
  //         case "/updatepassword":
  //           return (
  //             user?.tblUsercategory?.categorycode === "4" && (
  //               <Route key={index} path={to} element={<ChangeUserPassword />} />
  //             )
  //           );
  //         case "/role":
  //           return <Route key={index} path={to} element={<Role />} />;
  //         case "/rolerights":
  //           return <Route key={index} path={to} element={<RoleRights />} />;
  //         case "/module":
  //           return <Route key={index} path={to} element={<Module />} />;
  //         case "/pages":
  //           return <Route key={index} path={to} element={<Pages />} />;
  //         case "/workflow":
  //           return <Route key={index} path={to} element={<WorkFlow />} />;
  //         case "/profile":
  //           return <Route key={index} path={to} element={<Profile />} />;
  //         case "/companydetails":
  //           return <Route key={index} path={to} element={<CompanyDetails />} />;
  //         case "/pl":
  //           return <Route key={index} path={to} element={<Pl />} />;
  //         case "/pllist":
  //           return <Route key={index} path={to} element={<ViewPlList />} />;
  //         case "/pldetails":
  //           return <Route key={index} path={to} element={<PlDetails />} />;
  //         case "/el":
  //           return <Route key={index} path={to} element={<El />} />;
  //         case "/ellist":
  //           return <Route key={index} path={to} element={<ViewElList />} />;
  //         case "/eldetails/:code":
  //           return <Route key={index} path={to} element={<ElDetails />} />;
  //         case "/immigration":
  //           return <Route key={index} path={to} element={<Immigration />} />;
  //         case "/immigrationList":
  //           return (
  //             <Route key={index} path={to} element={<ViewImmigrationList />} />
  //           );
  //         case "/immigrationDetails":
  //           return (
  //             <Route key={index} path={to} element={<ImmigrationDetails />} />
  //           );
  //         case "/medicalNegligence":
  //           return (
  //             <Route key={index} path={to} element={<MedicalNegligence />} />
  //           );
  //         case "/medicalNegligenceList":
  //           return (
  //             <Route
  //               key={index}
  //               path={to}
  //               element={<ViewMedicalNegligenceList />}
  //             />
  //           );
  //         case "/medicalNegligenceDetails":
  //           return (
  //             <Route
  //               key={index}
  //               path={to}
  //               element={<MedicalNegligenceDetails />}
  //             />
  //           );
  //         case "/hire":
  //           return <Route key={index} path={to} element={<Hire />} />;
  //         case "/hireDetails":
  //           return <Route key={index} path={to} element={<HireDetails />} />;
  //         case "/rta":
  //           return <Route key={index} path={to} element={<RTA />} />;
  //         case "/hireList":
  //           return <p>fdsf</p>;
  //         case "/rtalist":
  //           return <Route key={index} path={to} element={<ViewRTAList />} />;
  //         case "/caselist":
  //           return <Route key={index} path={to} element={<CaseList />} />;
  //         case "/hdrCaseReport":
  //           return <Route key={index} path={to} element={<HdrCaseReport />} />;
  //         case "/hireCaseReport":
  //           return <Route key={index} path={to} element={<HireCaseReport />} />;
  //         case "/rtadetails/:rtacode":
  //           return <Route key={index} path={to} element={<RtaDetails />} />;
  //         case "/manualinvoice":
  //           return <Route key={index} path={to} element={<ManualInvoice />} />;
  //         case "/manualinvoicedetails/:id":
  //           return (
  //             <Route key={index} path={to} element={<ManualInvoiceDetails />} />
  //           );
  //         case "/broadcast":
  //           return <Route key={index} path={to} element={<AddBroadcast />} />;
  //         case "/viewallbroadcast":
  //           return (
  //             <Route key={index} path={to} element={<ViewAllBroadcast />} />
  //           );
  //         case "/userchangepassword":
  //           return <Route key={index} path={to} element={<BroadcastDescr />} />;
  //         case "/addcompanycfa":
  //           return <Route key={index} path={to} element={<AddCompanyCFA />} />;
  //         default:
  //           return <Route key={index} path={"/"} element={<Dashboard />} />;
  //       }
  //     })
  //   );
  // };

  const privateRoutes = (
    <>
      <Route path="/" element={<Dashboard />} />
      {user?.tblUsercategory?.categorycode !== "2" && (
        <Route path="/hdr" element={<Hdr />} />
      )}
      {user?.tblUsercategory?.categorycode !== "2" && (
        <Route path="/tenancy" element={<Tenant />} />
      )}
      <Route path="/viewhdr" element={<ViewHdr />} />
      <Route path="/hdrDetails/:hdrCode" element={<HdrDetails />} />
      <Route path="/pcp" element={<PCP />} />
      <Route path="/pcpDetails/:code" element={<PCPDetails />} />
      <Route path="/pcpList" element={<ViewPCPList />} />
      <Route path="/dataBreach" element={<DataBreach />} />
      <Route path="/dataBreachDetails" element={<DataBreachDetails />} />
      <Route path="/dataBreachList" element={<ViewDataBreachList />} />
      <Route path="/tenancylist" element={<ViewTenancyList />} />
      <Route path="/tenancyDetails" element={<TenancyDetails />} />
      <Route path="/ol" element={<Ol />} />
      <Route path="/ollist" element={<ViewOlList />} />
      <Route path="/oldetails/:code" element={<OlDetails />} />
      {user?.tblUsercategory?.categorycode === "4" && (
        <>
          <Route path="/billingList" element={<ViewInvoiceList />} />
          <Route path="/invoiceDetails" element={<InvoiceDetails />} />
          <Route path="/invoiceFilter" element={<InvoiceFilter />} />
          <Route path="/updatepassword" element={<ChangeUserPassword />} />
          <Route path="/profile" element={<ViewAllCompanies />} />
          <Route path="/companydetails" element={<CompanyDetails />} />
          <Route path="/role" element={<Role />} />
          <Route path="/rolerights" element={<RoleRights />} />
          <Route path="/module" element={<Module />} />
          <Route path="/pages" element={<Pages />} />
          <Route path="/workflow" element={<WorkFlow />} />
        </>
      )}

      <Route path="/pl" element={<Pl />} />
      <Route path="/pllist" element={<ViewPlList />} />
      <Route path="/pldetails/:code" element={<PlDetails />} />
      <Route path="/el" element={<El />} />
      <Route path="/ellist" element={<ViewElList />} />
      <Route path="/eldetails/:code" element={<ElDetails />} />
      <Route path="/immigration" element={<Immigration />} />
      <Route path="/immigrationList" element={<ViewImmigrationList />} />
      <Route path="/immigrationDetails" element={<ImmigrationDetails />} />
      <Route path="/medicalNegligence" element={<MedicalNegligence />} />
      <Route
        path="/medicalNegligenceList"
        element={<ViewMedicalNegligenceList />}
      />
      <Route
        path="/medicalNegligenceDetails/:code"
        element={<MedicalNegligenceDetails />}
      />
      <Route path="/hire" element={<Hire />} />
      <Route path="/hireDetails/:hrcode" element={<HireDetails />} />
      <Route path="/rta" element={<RTA />} />
      <Route path="/hireList" element={<ViewHireList />} />
      <Route path="/rtalist" element={<ViewRTAList />} />
      <Route path="/caselist" element={<CaseList />} />
      <Route path="/hdrCaseReport" element={<HdrCaseReport />} />
      <Route path="/hireCaseReport" element={<HireCaseReport />} />
      <Route path="/rtadetails/:rtacode" element={<RtaDetails />} />
      <Route path="/manualinvoice" element={<ManualInvoice />} />
      <Route
        path="/manualinvoicedetails/:id"
        element={<ManualInvoiceDetails />}
      />
      <Route path="/broadcast" element={<AddBroadcast />} />
      <Route path="/viewallbroadcast" element={<ViewAllBroadcast />} />
      <Route path="/userchangepassword" element={<BroadcastDescr />} />
      <Route path="/addcompanycfa" element={<AddCompanyCFA />} />
      <Route path="/emailstatus" element={<EmailStatus />} />
      <Route path="/viewallcompanies" element={<ViewAllCompanies />} />
      <Route path="/viewcompanyprofile/:id" element={<ViewCompanyProfile />} />
      <Route path="/viewcompanyjobs/:id" element={<ViewCompanyJobs />} />
      <Route path="/viewcompanyusers/:id" element={<ViewCompanyUsers />} />
      <Route path="/viewcompanydoc/:id" element={<CompanyDoc />} />

      {routes}
    </>
  );

  const privateRoutesUpdatePwd = (
    <>{<Route path="/" element={<UserChangePassword />} />}</>
  );

  const publicRoutes = (
    <>
      {routes}
      <Route path="/" element={<Login setValidToken={setValidToken} />} />
    </>
  );

  useIdleTimer({
    // timeout: idleLogoutTimeMM || 1800000000,
    timeout: 7200000, // 2 hours
    onIdle: logout,
    debounce: 500,
  });

  useEffect(() => {
    const interval = setInterval(async () => {
      if (token) {
        await refreshToken();
      }
    }, 840000); // 14 minutes

    return () => clearInterval(interval);
  }, [token]);

  if (token && !validToken) {
    return <AuthVerify setValidToken={setValidToken} token={token} />;
  }

  // if (token && isTokenExpiredForRelogin) {
  //   return <TokenExpire />;
  // }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnFocusLoss
        draggable
      />
      <div className="App">
        <BrowserRouter>
          {user && pwdUpdateFlag === "Y" && <Navbar />}
          <Layout>
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
              <Routes>
                {token && pwdUpdateFlag === "Y"
                  ? privateRoutes
                  : token && pwdUpdateFlag === "N"
                  ? privateRoutesUpdatePwd
                  : publicRoutes}
                <Route path="/email-redirect" element={<EmailLinkRedirect />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </LoadingOverlay>
          </Layout>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
