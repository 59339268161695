import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { handleGetRequest } from "../../services/GetTemplate";
import { Chip } from "primereact/chip";
import { compareAsc } from "date-fns";
import { FaBriefcase, FaFilePdf, FaUser, FaUsers } from "react-icons/fa";
import { Button } from "primereact/button";
import AddProfileButton from "./CompanyProfile.js/AddProfile/AddProfileButton";
import DownloadAllCompanies from "./DownloadAllCompanies";

function ViewAllCompanies() {
  const navigate = useNavigate();
  const [comapanyList, setComapanyList] = useState([]);
  const dt = useRef(null);

  const actionsTemplate = ({ companycode }) => {
    return (
      <div className="d-flex justify-content-around">
        <Button
          tooltip="Profile"
          tooltipOptions={{ position: "top" }}
          style={{ color: "#FF5722" }}
          className="btn btn-sm p-button-text"
          onClick={() => {
            navigate(`../viewcompanyprofile/${companycode}`);
          }}
        >
          <FaUser />
        </Button>
        <Button
          tooltip="Jobs"
          tooltipOptions={{ position: "top" }}
          style={{ color: "#3F51B5" }}
          className="btn btn-sm p-button-text"
          onClick={() => {
            navigate(`../viewcompanyjobs/${companycode}`);
          }}
        >
          <FaBriefcase />
        </Button>
        <Button
          tooltip="Users"
          tooltipOptions={{ position: "top" }}
          style={{ color: "#4CAF50" }}
          className="btn p-button-text"
          onClick={() => {
            navigate(`../viewcompanyusers/${companycode}`);
          }}
        >
          <FaUsers />
        </Button>
        <Button
          tooltip="Document"
          tooltipOptions={{ position: "top" }}
          style={{ color: "#4CAF50" }}
          className="btn p-button-text"
          onClick={() => {
            navigate(`../viewcompanydoc/${companycode}`);
          }}
        >
          <FaFilePdf />
        </Button>
      </div>
    );
  };

  useEffect(() => {
    const getComapanyList = async () => {
      try {
        const response = await handleGetRequest("getAllCompaniesProfile");
        setComapanyList(response?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getComapanyList();
  }, []);

  const statusTemplate = (rowData) => {
    return (
      <Chip
        label={rowData?.companystatus === "Y" ? "Active" : "Inactive"}
        className="custom-chip p-px-4"
      />
    );
  };

  const dateSortFunction = (event) => {
    const value = [...comapanyList];
    value.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 === null && value2 !== null) result = -1;
      else if (value1 !== null && value2 === null) result = 1;
      else if (value1 === null && value2 === null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string") {
        // Parse date strings in DD-MM-YYYY format to compare
        const [day1, month1, year1] = value1.split("-");
        const [day2, month2, year2] = value2.split("-");
        const date1 = new Date(`${year1}-${month1}-${day1}`);
        const date2 = new Date(`${year2}-${month2}-${day2}`);
        result = date1 - date2;
      } else result = compareAsc(new Date(value1), new Date(value2));

      return event.order * result;
    });

    return value;
  };

  const renderHeader = () => {
    return (
      <div className="table-header d-flex justify-content-between">
        <h4>Company List</h4>
        <div className="d-flex">
          <AddProfileButton />
          <DownloadAllCompanies />
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <DataTable
        ref={dt}
        value={comapanyList || []}
        loading={comapanyList === null}
        paginator
        rows={10}
        emptyMessage="No data found"
        header={renderHeader()}
      >
        <Column
          field="name"
          sortable
          header="Name"
          filter
          filterMatchMode="contains"
        />
        <Column
          field="createdon"
          sortable
          header="Added on"
          filter
          filterMatchMode="contains"
          sortFunction={dateSortFunction} // Custom sort function for dates
        />
        <Column
          field="addressline1"
          sortable
          header="Location"
          filter
          filterMatchMode="contains"
        />
        <Column
          field="companystatus"
          sortable
          body={statusTemplate}
          header="Status"
          filter
          filterMatchMode="contains"
        />
        <Column header="Action" body={actionsTemplate} />
      </DataTable>
    </div>
  );
}

export default ViewAllCompanies;
