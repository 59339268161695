import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaHourglassHalf } from "react-icons/fa";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 96px;
  color: #343a40;
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  color: #6c757d;
  margin-bottom: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 30px;
`;

const CountdownWrapper = styled.div`
  .countdown-icon {
    font-size: 50px;
    color: #ff6f61;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .countdown-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #495057;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

function NotFound() {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);

    if (counter === 0) {
      navigate("/"); // Redirect to home page
    }

    return () => clearInterval(timer); // Cleanup the timer
  }, [counter, navigate]);

  return (
    <Wrapper>
      <Title>404</Title>
      <Subtitle>Looks like you're lost</Subtitle>
      <Text>The page you are looking for is not available!</Text>
      <CountdownWrapper>
        <FaHourglassHalf className="countdown-icon" />
        <div className="countdown-text">
          Redirecting to Home in {counter} seconds...
        </div>
      </CountdownWrapper>
      <Button onClick={() => navigate("/")}>Go to Home</Button>
    </Wrapper>
  );
}

export default NotFound;
