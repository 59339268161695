import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginAction } from "../../redux/actions/authAction";
import "./Login.css";
import VerifyOtp from "../../components/verifyOtp/VerifyOtp";
import { handlePostRequest } from "../../services/PostTemplate";

function Login({ redirectUrl, setValidToken }) {
  const [otpGen, setOtpGen] = useState(false);
  const [loading, setLoading] = useState(false);

  const sanitizeInput = (value) => {
    return value.trim(); // Remove leading and trailing spaces or newlines
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Validation Schema
  const validationSchema = Yup.object({
    userName: Yup.string().required("Username / email is required"),
    password: Yup.string()
      .min(3, "Password must be at least 3 characters")
      .required("Password is required"),
  });

  // Formik Setup
  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (process.env.NODE_ENV === "development") {
        await loginForDeveloper(values);
        return;
      }

      const sanitizedValues = {
        userName: sanitizeInput(values.userName),
        password: sanitizeInput(values.password),
      };

      setLoading(true);
      try {
        const res = await handlePostRequest(sanitizedValues, "Otplogin");
        setOtpGen(res.data?.otpGen || false);
      } catch (err) {
        console.error("Login Error:", err);
        setOtpGen(false);
      } finally {
        setLoading(false);
      }
    },
  });

  const loginForDeveloper = async (values) => {
    setLoading(true);
    try {
      const sanitizedValues = {
        userName: sanitizeInput(values.userName),
        password: sanitizeInput(values.password),
      };
      const res = await dispatch(loginAction(sanitizedValues));
      processLoginResponse(res);
    } catch (err) {
      console.error("Developer Login Failed:", err);
      setValidToken(false);
    } finally {
      setLoading(false);
    }
  };

  const onVerifyOtp = async (otp) => {
    const sanitezedValues = {
      userName: sanitizeInput(formik.values.userName),
      password: sanitizeInput(formik.values.password),
    };
    const data = { ...sanitezedValues, otp };
    setLoading(true);
    try {
      const res = await dispatch(loginAction(data));
      processLoginResponse(res);
    } catch (err) {
      console.error("OTP Verification Failed:", err);
      setValidToken(false);
    } finally {
      setLoading(false);
    }
  };

  const processLoginResponse = (res) => {
    if (res?.login) {
      setValidToken(true);
      navigate(redirectUrl || "../", { replace: true });
    } else {
      setValidToken(false);
    }
  };

  return (
    <>
      {otpGen ? (
        <VerifyOtp onVerifyOtp={onVerifyOtp} />
      ) : (
        <form
          className="login-wrapper"
          onSubmit={formik.handleSubmit}
          onKeyDown={(e) => e.keyCode === 13 && formik.handleSubmit()}
        >
          <div className="box-shadow">
            <div className="popup-header">
              <span className="text-semibold">User Login</span>
            </div>
            <div className="well" id="login">
              <div className="form-group has-feedback">
                <label htmlFor="userName">
                  User Name <span className="text-danger">*</span>
                </label>
                <input
                  id="userName"
                  name="userName"
                  className={`form-control ${
                    formik.touched.userName && formik.errors.userName
                      ? "is-invalid"
                      : ""
                  }`}
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                <span className="input-icon">
                  <ImUsers />
                </span>
                {formik.touched.userName && formik.errors.userName && (
                  <div className="invalid-feedback">
                    {formik.errors.userName}
                  </div>
                )}
              </div>

              <div className="form-group has-feedback mt-4">
                <label htmlFor="password">
                  Password <span className="text-danger">*</span>
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={`form-control ${
                    formik.touched.password && formik.errors.password
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                />
                <span className="input-icon">
                  <RiLockPasswordFill />
                </span>
                {formik.touched.password && formik.errors.password && (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                )}
              </div>

              <div className="mt-4 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary d-flex align-items-center"
                  disabled={loading}
                >
                  {loading ? (
                    <i className="pi pi-spin pi-spinner"></i>
                  ) : (
                    <FaBars />
                  )}
                  <span className="ms-2">Sign in</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default Login;
