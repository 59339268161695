import React, { useState } from "react";
import { MainWrapper } from "../../components/FormLayout/FormHeader/index";
import {
  SideBoxWrapper,
  SideBox,
} from "../../components/FormLayout/SideBoxSection/index";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { AiOutlineBars } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import UploadModal from "../../components/UploadModal";
import ViewHireForm from "./ViewHireForm";
import { BsLink } from "react-icons/bs";
import { BsClipboardMinus } from "react-icons/bs";
import { HiPencil } from "react-icons/hi";
import { BiMailSend } from "react-icons/bi";
import CaseHeaderHire from "./CaseHeaderHire";
import { handlePostRequest } from "../../services/PostTemplate";
import { getHireDetails } from "../../redux/actions/hireAction";
import { Dialog } from "primereact/dialog";
import HireCompanies from "./HireCompanies";
import InvoiceCaseDetails from "../../components/InvoiceCaseDetails.js/InvoiceCaseDetails";
import HireTasks from "./HireTasks";
import ESignAuditButton from "../../components/ESignAudit/ESignAuditButton";
import { useParams } from "react-router-dom";
import { Button } from "primereact/button";
import CaseAccountInfo from "../../components/CaseAccountInfo/CaseAccountInfo";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";

export default function HireDetails() {
  const hire = useSelector((state) => state?.hireSlice?.hire) || {};
  const [showUpload, setShowUpload] = useState(false);
  const [hdrTaskCode, setHdrTaskCode] = useState("");
  const tblHirebusinesses = hire?.tblHirebusinesses || [];
  const [showModal, setshowModal] = useState(false);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const { hrcode } = useParams();
  const dispatch = useDispatch();

  const handleBusinessBtn = async (companycode, status) => {
    const data = {
      companyCode: companycode,
      hireclaimcode: hire?.hirecode,
      status,
    };
    await handlePostRequest(data, `hire/changeBusinessStatus`);
    dispatch(getHireDetails(hire?.hirecode));
  };

  return (
    <>
      <CaseHeaderHire
        title={hire.hirenumber}
        subTitle={` ${hire?.statusDescr}`}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeaderHire>
      <MainWrapper>
        <ViewHireForm hrCode={hrcode} />
        <SideBoxWrapper>
          <HireTasks />
          {user?.tblUsercategory?.categorycode === "1" ||
          user?.tblUsercategory?.categorycode === "4" ? (
            <SideBox title="Businesses" className="mt-4">
              {tblHirebusinesses.map((item) => (
                <>
                  <strong>{item?.tblCompanyprofile?.name}</strong>
                  <br></br>
                  <small>Assigned User: {item?.statususername}</small>
                  <br></br>
                  <small className="text-danger">
                    Handler email: {item?.handleremail}
                  </small>
                  <div className="d-flex align-items-center justify-content-end mt-4">
                    <Button
                      onClick={() =>
                        handleBusinessBtn(
                          item?.tblCompanyprofile?.companycode,
                          "R"
                        )
                      }
                      tooltip="Change Business Status to Reject"
                      tooltipOptions={{ position: "top" }}
                      style={{ color: "black" }}
                      className="btn btn-sm p-button-text"
                    >
                      <BsLink size="20px" className="p-ml-2" />
                    </Button>
                    <Button
                      onClick={() =>
                        handleBusinessBtn(
                          item?.tblCompanyprofile?.companycode,
                          "W"
                        )
                      }
                      tooltip="Change Business Status to Widthdraw"
                      tooltipOptions={{ position: "top" }}
                      style={{ color: "black" }}
                      className="btn btn-sm p-button-text"
                    >
                      <BsClipboardMinus size="20px" className="p-ml-2" />
                    </Button>
                    <Button
                      onClick={() => setshowModal(true)}
                      tooltip="Change Business User"
                      tooltipOptions={{ position: "top" }}
                      style={{ color: "black" }}
                      className="btn btn-sm p-button-text"
                    >
                      <HiPencil size="20px" className="p-ml-2" />
                    </Button>
                    <Button
                      onClick={() =>
                        handleBusinessBtn(
                          item?.tblCompanyprofile?.companycode,
                          "P"
                        )
                      }
                      tooltip="Change Business Status to Pending"
                      tooltipOptions={{ position: "top" }}
                      style={{ color: "black" }}
                      className="btn btn-sm p-button-text"
                    >
                      <BiMailSend size="20px" className="p-ml-2" />
                    </Button>
                  </div>
                  <hr />
                </>
              ))}
              <small> {hire?.introducername}</small>
              <br></br>
              <strong>Assigned User:</strong> <small>{hire?.advisorname}</small>
              <div className="d-flex align-items-center justify-content-end">
                <BsClipboardMinus size="20px" className="p-ml-2" />
              </div>
            </SideBox>
          ) : null}

          <CaseAccountInfo
            introducerName={hire?.introducername}
            advisorName={hire?.advisorname}
            campaign={hire}
            campaignName={campaignsNames.hire}
            getCampaignDetails={getHireDetails}
          />

          <InvoiceCaseDetails
            introducerInvoiceDate={hire?.introducerInvoiceDate}
            solicitorInvoiceDate={hire?.solicitorInvoiceDate}
            introducerInvoiceHeadId={hire?.introducerInvoiceHeadId}
            solicitorInvoiceHeadId={hire?.solicitorInvoiceHeadId}
            introducerInvoiceStatus={hire?.introducerInvoiceStatus}
            solicitorInvoiceStatus={hire?.solicitorInvoiceStatus}
          />
          <ESignAuditButton data={hire?.tblEsignStatus} />

          {user?.tblUsercategory?.categorycode === "1" ||
          user?.tblUsercategory?.categorycode === "4" ? (
            <SideBox title="Process Timeline" className="mt-4">
              <div className="history-tl-container">
                <ul className="tl">
                  {hire?.tblHirelogs?.map((item, index) => (
                    <li className="tl-item" key={item.hirelogcode}>
                      <div>
                        <strong>{item?.descr}</strong> <br />
                        <span>{item?.userName}</span>
                        <br />
                        <small>{item?.createdon}</small>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </SideBox>
          ) : null}
        </SideBoxWrapper>
      </MainWrapper>
      <UploadModal
        taskCode={hdrTaskCode}
        show={showUpload}
        hide={setShowUpload}
      />
      <Dialog
        header="Change Hire Business User"
        visible={showModal}
        style={{ width: "70vw" }}
        onHide={() => setshowModal(false)}
      >
        <HireCompanies />
      </Dialog>
    </>
  );
}
