import React from "react";
import styled from "styled-components";
import SideBox from "../FormLayout/SideBoxSection";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const InvoiceSection = styled.div`
  display: flex;
  align-items: center;
  color: #0000ee;
  cursor: pointer;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
  }
`;

const Pill = styled.span`
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 1em;
  font-weight: 600;
  color: white;
  background-color: ${(props) => {
    switch (props.status) {
      case "Pending Approval":
        return "#ffc107"; // Yellow
      case "Issued":
        return "#17a2b8"; // Cyan
      case "Paid":
        return "#28a745"; // Green
      case "Overdue":
        return "#dc3545"; // Red
      case "Voided":
        return "#6c757d"; // Gray
      case "Refunded":
        return "#20c997"; // Teal
      case "Disputed":
        return "#e83e8c"; // Pink
      default:
        return "#6c757d"; // Default gray
    }
  }};
  border-radius: 10rem;
  margin-left: 8px;
`;

function InvoiceCaseDetails({
  introducerInvoiceDate,
  solicitorInvoiceDate,
  introducerInvoiceStatus,
  solicitorInvoiceStatus,
  introducerInvoiceHeadId = null,
  solicitorInvoiceHeadId = null,
}) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const navigate = useNavigate();

  if (
    user?.tblUsercategory?.categorycode !== "4" ||
    introducerInvoiceHeadId === null ||
    solicitorInvoiceHeadId === null
  ) {
    return null;
  }

  const handleView = (invoiceHeadId) => {
    const path = `../invoiceDetails?invoicecode=${invoiceHeadId}`;
    navigate(path);
  };

  return (
    <SideBox title="Invoice Details" className="mt-4">
      <InvoiceSection onClick={() => handleView(introducerInvoiceHeadId)}>
        <div>
          <small>{introducerInvoiceDate}</small>
          <br />
          <strong>Introducer Invoice Date</strong>
        </div>
        <Pill status={introducerInvoiceStatus}>{introducerInvoiceStatus}</Pill>
      </InvoiceSection>
      <hr />
      <InvoiceSection onClick={() => handleView(solicitorInvoiceHeadId)}>
        <div>
          <small>{solicitorInvoiceDate}</small>
          <br />
          <strong>Solicitor Invoice Date</strong>
        </div>
        <Pill status={solicitorInvoiceStatus}>{solicitorInvoiceStatus}</Pill>
      </InvoiceSection>
    </SideBox>
  );
}

export default InvoiceCaseDetails;
