import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import styled from "styled-components";

const VerifyOtp = ({ onVerifyOtp }) => {
  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState("pi pi-check");

  const otpRefs = useRef([]);

  useEffect(() => {
    // Auto-focus on the first input
    otpRefs.current[0]?.focus();
  }, []);

  const addRef = (el) => {
    if (el && !otpRefs.current.includes(el)) {
      otpRefs.current.push(el);
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value)) {
      formik.setFieldValue(`otp${index + 1}`, value);

      if (index < 5) {
        otpRefs.current[index + 1]?.focus();
      } else {
        // Auto-submit when all inputs are filled
        const otp = Object.values(formik.values).join("") + value;
        if (otp.length === 6) {
          formik.submitForm();
        }
      }
    } else {
      formik.setFieldValue(`otp${index + 1}`, "");
    }
  };

  const handleFocus = (e) => {
    const valueLength = e.target.value.length;
    e.target.setSelectionRange(valueLength, valueLength);
  };

  const handleKeyNavigation = (e, index) => {
    if (
      e.key === "Backspace" &&
      !formik.values[`otp${index + 1}`] &&
      index > 0
    ) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < 5) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    },
    validate: (values) => {
      const errors = {};
      const otpValues = Object.values(values);
      if (otpValues.some((otp) => otp === "")) {
        errors.otp = "OTP is incomplete.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        setLoadingIcon("pi pi-spin pi-spinner");
        const otp = Object.values(values).join("");
        await onVerifyOtp(otp);
        setLoading(false);
        setLoadingIcon("pi pi-save");
        formik.resetForm();
        otpRefs.current[0]?.focus(); // Reset focus to the first input
      } catch (err) {
        setLoading(false);
        setLoadingIcon("pi pi-save");
        formik.resetForm();
        otpRefs.current[0]?.focus(); // Reset focus to the first input
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = () => {
    return (
      formik.errors.otp && (
        <small className="p-error">{formik.errors.otp}</small>
      )
    );
  };

  return (
    <Container>
      <Card>
        <div className="Form__Header">
          <h1>Verify OTP</h1>
        </div>
        <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
          <div className="p-mt-4">
            <p>
              Please enter the OTP code sent to your registered mobile number /
              email.
            </p>
            <OtpContainer>
              {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <Input
                  key={index}
                  maxLength="1"
                  ref={(el) => addRef(el)}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleKeyNavigation(e, index)}
                  onFocus={handleFocus}
                  value={formik.values[`otp${index + 1}`]}
                  name={`otp${index + 1}`}
                  type="text"
                  onBlur={formik.handleBlur}
                />
              ))}
            </OtpContainer>
            {getFormErrorMessage()}
            <div className="p-mt-4">
              <Button
                disabled={loading}
                icon={loadingIcon || ""}
                iconPos="right"
                label={"Verify"}
                className="Btn__Dark"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </form>
      </Card>
    </Container>
  );
};

const Input = styled.input`
  width: 50px;
  height: 50px;
  margin: 0 8px;
  font-size: 18px;
  text-align: center;
  border: 1px solid var(--surface-border, #ccc);
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  outline: none;
  transition: all 0.2s;
  &:focus {
    border-color: var(--primary-color, #007bff);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--surface-a, #f5f5f5);
  color: var(--text-color, #333);
  padding: 16px;
`;

const Card = styled.div`
  background: var(--surface-card, #fff);
  border: 1px solid var(--surface-border, #ddd);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 32px;
  width: 100%;
  max-width: 400px;
  text-align: center;
`;

const OtpContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export default VerifyOtp;
