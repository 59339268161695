import React from "react";
import LaInputNumber from "../../../../components/LaInputNumber/LaInputNumber";
import LaStaticDropdown from "../../../../components/LaStaticDropdown";

function HireJobFields({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const inputNumberProps = {
    ...props,
    mode: "currency",
    currency: "GBP",
  };

  const inputPercentProps = {
    ...props,
    mode: "percent",
  };

  return (
    <>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Standard Roadworthy</label>
        <LaInputNumber
          keyName="standardroadworthy"
          placeholder="Standard Roadworthy"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Standard Un Roadworthy</label>
        <LaInputNumber
          keyName="standardunroadworthy"
          placeholder="Standard Un Roadworthy"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Prestige Roadworthy</label>
        <LaInputNumber
          keyName="prestigeroadworthy"
          placeholder="Prestige Roadworthy"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Prestige Un Roadworthy</label>
        <LaInputNumber
          keyName="prestigeunroadworthy"
          placeholder="Prestige Un Roadworthy"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Bike</label>
        <LaInputNumber
          keyName="bike"
          placeholder="Bike"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Taxi</label>
        <LaInputNumber
          keyName="taxi"
          placeholder="Taxi"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Recovery</label>
        <LaInputNumber
          keyName="recovery"
          placeholder="Recovery"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Salvage</label>
        <LaInputNumber
          keyName="salvage"
          placeholder="Salvage"
          {...inputPercentProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Repairs</label>
        <LaInputNumber
          keyName="repairs"
          placeholder="Repairs"
          {...inputPercentProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Fault Repairs</label>
        <LaInputNumber
          keyName="faultRepairs"
          placeholder="Fault Repairs"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Status</label>
        <LaStaticDropdown
          keyName="status"
          optionLabel="label"
          options={[
            { value: true, label: "Active" },
            { value: false, label: "Inactive" },
          ]}
          {...props}
        />
      </div>
    </>
  );
}

export default HireJobFields;
