import React, { useState, useEffect, useCallback, useRef } from "react";
import { addNotes, getNotes } from "../../redux/actions/notesAction";
import { useDispatch } from "react-redux";
import "./Notes.css";
import { getLovUserCategory } from "../../services/Lovs";
import CategoryColors from "./CategoryColors";
import AdvancedSearch from "./AdvancedSearch";
import { toast } from "react-toastify";
import ResizeNotes, { ChatboxContainer } from "./ResizeNotes";
import CategoryFilter from "./CategoryFilter";
import { InputTextarea } from "primereact/inputtextarea";

function Notes({
  getNotesService,
  addNotesService,
  caseType,
  code,
  messages,
  onlyInternal = false,
}) {
  const dispatch = useDispatch();

  const [note, setnote] = useState("");
  const [sending, setsending] = useState(false);
  const [usersLov, setUsersLov] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [highlightedMessageIndex, setHighlightedMessageIndex] = useState(null);
  const [chatboxHeight, setChatboxHeight] = useState(460);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredMessages, setFilteredMessages] = useState([]);

  const messagesEndRef = useRef(null);

  const handleNotes = useCallback(async () => {
    dispatch(getNotes(getNotesService, code));
  }, [dispatch, code, getNotesService]);

  const handleAddNote = async () => {
    if (note) {
      let key = "rtaCode";
      if (caseType === "hire") {
        key = "hireCode";
      } else if (caseType === "hdr") {
        key = "hdrCode";
      } else if (caseType === "tenancy") {
        key = "tenancyCode";
      } else if (caseType === "pcp") {
        key = "pcpCode";
      } else if (caseType === "db") {
        key = "dbCode";
      } else if (caseType === "ol") {
        key = "olCode";
      } else if (caseType === "pl") {
        key = "plCode";
      } else if (caseType === "el") {
        key = "elCode";
      } else if (caseType === "im") {
        key = "dbCode";
      } else if (caseType === "mn") {
        key = "mNcode";
      } else if (caseType === "invoiceHeadId") {
        key = "invoiceHeadId";
      } else key = caseType;

      setsending(true);
      await dispatch(
        addNotes(addNotesService, {
          note,
          [key]: code,
          userCatCode: selectedUser,
        })
      );
      await handleNotes();
      setnote();
      setsending(false);
    }
  };

  const loggedIn = JSON.parse(localStorage.getItem("loggedIn")).tblUsercategory
    .categorycode;

  const handleUserLov = async () => {
    let userList = await getLovUserCategory();

    switch (Number(loggedIn)) {
      case 1:
        userList = userList.filter((item) => item.code === "4");
        break;
      case 2:
        userList = userList.filter((item) => item.code === "4");
        break;
      case 4:
        break;
      default:
        userList = userList.filter((item) => item.code === "4");
        break;
    }
    setUsersLov(
      onlyInternal ? userList.filter((item) => item.code === "4") : userList
    );
    setSelectedUser(onlyInternal ? "4" : userList[0]?.code);
  };

  useEffect(() => {
    if (!messages) {
      setFilteredMessages([]);
      return;
    }

    const sortMessages = () => {
      return (
        messages.length &&
        [...messages].sort((a, b) => {
          // Parse the createdon field into Date objects for comparison
          const dateA = new Date(
            a.createdon.split(" ")[0].split("-").reverse().join("-") +
              "T" +
              a.createdon.split(" ")[1]
          );
          const dateB = new Date(
            b.createdon.split(" ")[0].split("-").reverse().join("-") +
              "T" +
              b.createdon.split(" ")[1]
          );
          return dateB - dateA; // Sort in descending order
        })
      );
    };

    if (messages && messages.length) {
      setFilteredMessages(sortMessages());
    }
  }, [messages]);

  useEffect(() => {
    handleUserLov();
    handleNotes();
  }, [handleNotes]);

  const handleSearchNotes = (query) => {
    const filteredResults = filteredMessages?.reduce((acc, message, index) => {
      if (message.note.includes(query)) {
        acc.push({ index, message });
      }
      return acc;
    }, []);
    if (!filteredResults.length) {
      toast.error("No results found");
    }

    setSearchResults(filteredResults);
  };

  const scrollToResult = (index) => {
    setHighlightedMessageIndex(index);

    const resultElement = document.getElementById(`scroll-msg-${index}`);
    if (resultElement) {
      resultElement.scrollIntoView({ behavior: "smooth" });
    }

    setTimeout(() => {
      setHighlightedMessageIndex(null);
    }, 5000);
  };

  const onClear = () => {
    setSearchResults([]);
  };

  const handleCategoryChange = (event) => {
    const selected = event.target.value;

    setSelectedCategory(selected);
    onClear();

    // Perform filtering based on the selected category
    if (selected === "All") {
      setFilteredMessages(messages);
    } else {
      const filtered = messages.filter(
        (item) => item?.usercategorycode === getCategoryCode(selected)
      );
      setFilteredMessages(filtered);
    }
  };

  const getCategoryCode = (category) => {
    switch (category) {
      case "Internal":
        return "4";
      case "Solicitor":
        return "2";
      case "Third Party":
        return "7";
      case "Introducer":
        return "1"; // Add the code for "Introducer"
      default:
        return ""; // Handle other cases if needed
    }
  };

  return (
    <div className="mt-4">
      <h6>
        <strong>Notes</strong>
      </h6>

      {filteredMessages && filteredMessages.length ? (
        <AdvancedSearch
          onSearch={handleSearchNotes}
          searchResults={searchResults}
          scrollToResult={scrollToResult}
          onClear={onClear}
        />
      ) : null}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <CategoryFilter
          selectedCategory={selectedCategory}
          onCategoryChange={handleCategoryChange}
        />
        {/* <ResizeNotes setChatboxHeight={setChatboxHeight} /> */}
      </div>
      <ChatboxContainer height={chatboxHeight}>
        <div className="chat-input mb-4">
          <InputTextarea
            rows={5}
            cols={50}
            type="text"
            disabled={sending}
            autoComplete="true"
            value={note || ""}
            onChange={(e) => setnote(e.target.value)}
            placeholder="Type a message"
            autoResize={false}
          />
          <div>
            <select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              {usersLov &&
                usersLov.map((user) => (
                  <option value={user?.code} key={user?.code}>
                    {user?.name}
                  </option>
                ))}
            </select>
          </div>
          <button disabled={sending} onClick={handleAddNote}>
            <i
              className={sending ? "pi pi-spin pi-spinner" : "pi pi-send "}
            ></i>
          </button>
        </div>
        <section className="chat-window">
          {filteredMessages && filteredMessages.length
            ? filteredMessages.map((item, index) =>
                item?.self ? (
                  <article
                    key={item?.rtanotecode}
                    className={`msg-container ${
                      item?.usercategorycode === "4"
                        ? "msg-internal"
                        : item?.usercategorycode === "2"
                        ? "msg-solicitor"
                        : item?.usercategorycode === "7"
                        ? "msg-thirdparty"
                        : "msg-introducer"
                    } ${
                      highlightedMessageIndex === index ? "highlighted" : ""
                    }`}
                    id={`scroll-msg-${index}`}
                  >
                    <div className="msg-box">
                      <div className="flritem?.rtanotecode === 1 ? (">
                        <div className="messages">
                          <p className="msg" id={`scroll-msg-${index}`}>
                            {item?.note}
                          </p>
                        </div>
                        <span className="timestamp">
                          <span className="username">{item?.userName}</span>
                          &bull;
                          <span className="posttime">{item?.createdon}</span>
                        </span>
                        <p className="usercat">
                          {item?.usercategorycode === "4"
                            ? "Internal"
                            : item?.usercategorycode === "1"
                            ? "Introducer"
                            : item?.usercategorycode === "7"
                            ? "Third Party"
                            : "Solicitor"}
                        </p>
                      </div>
                    </div>
                  </article>
                ) : (
                  <article
                    key={item?.rtanotecode}
                    className={`msg-container ${
                      highlightedMessageIndex === index ? "highlighted" : ""
                    }`}
                    id={`scroll-msg-${index}`}
                  >
                    <div
                      className={
                        item?.fromUserCategory === "4"
                          ? "msg-box color-internal"
                          : item?.fromUserCategory === "1"
                          ? "msg-box color-introducer"
                          : item?.fromUserCategory === "7"
                          ? "msg-box color-thirdparty"
                          : "msg-box color-solicitor"
                      }
                    >
                      <div className="flr">
                        <div className="messages">
                          <p className="msg" id={`scroll-msg-${index}`}>
                            {item?.note}
                          </p>
                        </div>
                        <span className="timestamp">
                          <span className="username">{item?.userName}</span>
                          &bull;
                          <span className="posttime">{item?.createdon}</span>
                        </span>
                        <p className="usercat">
                          {item?.usercategorycode === "4"
                            ? "Internal"
                            : item?.usercategorycode === "1"
                            ? "Introducer"
                            : item?.usercategorycode === "7"
                            ? "Third Party"
                            : "Solicitor"}
                        </p>
                      </div>
                    </div>
                  </article>
                )
              )
            : null}

          <div ref={messagesEndRef} />
        </section>
      </ChatboxContainer>
      <CategoryColors />
    </div>
  );
}

export default Notes;
