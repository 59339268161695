import React, { useRef, useState } from "react";
import CaseHeaderRta from "./CaseHeaderRta/index";
import { MainWrapper } from "../../components/FormLayout/FormHeader/index";
import { SideBoxWrapper } from "../../components/FormLayout/SideBoxSection/index";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { AiOutlineBars } from "react-icons/ai";
import { useSelector } from "react-redux";
import UploadModal from "../../components/UploadModal";
import ViewRtaForm from "./ViewRtaForm";
import RtaTasks from "./RtaTasks";
import RtaAddPassengers from "./RtaAddPassengers";
import RtaSideBoxPassengersLinkToMain from "./RtaSideBoxPassengersLinkToMain";
import RTASideBoxTimeline from "./RTASideBoxTimeline";
import InvoiceCaseDetails from "../../components/InvoiceCaseDetails.js/InvoiceCaseDetails";
import ESignAuditButton from "../../components/ESignAudit/ESignAuditButton";
import { toast } from "react-toastify";
import CaseAccountInfo from "../../components/CaseAccountInfo/CaseAccountInfo";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";
import { getrtaDetails } from "../../redux/actions/rtaAction";

export default function RtaDetails() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const [showUpload, setShowUpload] = useState(false);
  const advisorname = rta?.advisorname || "";
  const introducername = rta?.introducername || "";
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const formikRef = useRef(null);
  const [validFormHotKeys, setValidFormHotKeys] = useState(false);
  const [validForm, setValidForm] = useState(false);

  const handleValidateAndSubmit = (isHotkey = false) => {
    if (formikRef.current) {
      formikRef.current.validateForm().then((errors) => {
        if (Object.keys(errors).length === 0) {
          if (isHotkey) {
            setValidFormHotKeys(true);
          } else {
            setValidForm(true);
          }
        } else {
          // If there are errors, set touched for all fields to show validation messages
          formikRef.current.setTouched(
            Object.keys(errors).reduce(
              (acc, key) => ({ ...acc, [key]: true }),
              {}
            )
          );
          toast.error("Please fill in all required fields");
          if (isHotkey) {
            setValidFormHotKeys(false);
          } else {
            setValidForm(false);
          }
        }
      });
    }
  };

  return (
    <>
      <CaseHeaderRta
        title={rta.rtanumber}
        handleValidateAndSubmit={handleValidateAndSubmit}
        validFormHotKeys={validFormHotKeys}
        setValidFormHotKeys={setValidFormHotKeys}
        validForm={validForm}
        setValidForm={setValidForm}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeaderRta>
      <MainWrapper>
        <ViewRtaForm formikRef={formikRef} />
        <SideBoxWrapper>
          {user?.tblUsercategory?.categorycode === "4"
            ? rta?.rtaCaseTasks?.length > 0 && <RtaTasks />
            : null}
          {(user?.tblUsercategory?.categorycode === "4" ||
            user?.tblUsercategory?.categorycode === "1") && (
            <CaseAccountInfo
              introducerName={introducername}
              advisorName={advisorname}
              campaign={rta}
              campaignName={campaignsNames.rta}
              getCampaignDetails={getrtaDetails}
            />
          )}
          <InvoiceCaseDetails
            introducerInvoiceDate={rta?.introducerInvoiceDate}
            solicitorInvoiceDate={rta?.solicitorInvoiceDate}
            introducerInvoiceHeadId={rta?.introducerInvoiceHeadId}
            solicitorInvoiceHeadId={rta?.solicitorInvoiceHeadId}
            introducerInvoiceStatus={rta?.introducerInvoiceStatus}
            solicitorInvoiceStatus={rta?.solicitorInvoiceStatus}
          />
          <ESignAuditButton data={rta?.tblEsignStatus} />
          {user?.tblUsercategory?.categorycode === "1" ||
          user?.tblUsercategory?.categorycode === "4"
            ? rta?.rtalinkcode === "" ||
              (rta?.rtalinkcode === null && <RtaAddPassengers />)
            : null}
          <RtaSideBoxPassengersLinkToMain />
          <RTASideBoxTimeline />
        </SideBoxWrapper>
      </MainWrapper>
      <UploadModal show={showUpload} hide={setShowUpload} />
    </>
  );
}
